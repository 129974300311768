/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: EmailIDInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($input: EmailIDInput) {
    ListUsers(input: $input)
  }
`;
export const listCredentials = /* GraphQL */ `
  query ListCredentials($input: ListCredentialsInput) {
    listCredentials(input: $input)
  }
`;
export const yearlyReport = /* GraphQL */ `
  query YearlyReport($input: yearlyReportInput) {
    YearlyReport(input: $input)
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries($input: ListCountriesInput!) {
    ListCountries(input: $input)
  }
`;
export const listPDFTemplates = /* GraphQL */ `
  query ListPDFTemplates($input: ListPDFTemplatesInput) {
    ListPDFTemplates(input: $input)
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia($input: listMediaInput) {
    ListMedia(input: $input)
  }
`;
export const listVerificationRequests = /* GraphQL */ `
  query ListVerificationRequests($input: ListVerificationRequestsInput) {
    ListVerificationRequests(input: $input)
  }
`;
export const previewPDFTemplate = /* GraphQL */ `
  query PreviewPDFTemplate($input: PreviewPDFTemplateInput) {
    PreviewPDFTemplate(input: $input)
  }
`;
