/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    CreateUser(input: $input)
  }
`;
export const activateOrDeactivateUser = /* GraphQL */ `
  mutation ActivateOrDeactivateUser($input: ActivateOrDeactivateUserInput) {
    ActivateOrDeactivateUser(input: $input)
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput) {
    UpdateUserDetails(input: $input)
  }
`;
export const issueCredential = /* GraphQL */ `
  mutation IssueCredential($input: IssueCredentialInput) {
    IssueCredential(input: $input)
  }
`;
export const issueCredentialWithoutDoc = /* GraphQL */ `
  mutation IssueCredentialWithoutDoc($input: IssueCredentialWithoutDocInput) {
    IssueCredentialWithoutDoc(input: $input)
  }
`;
export const issueCredentailWithTemplate = /* GraphQL */ `
  mutation IssueCredentailWithTemplate(
    $input: IssueCredentailWithTemplateInput
  ) {
    IssueCredentailWithTemplate(input: $input)
  }
`;
export const transferOwnership = /* GraphQL */ `
  mutation TransferOwnership($input: TransferOwnershipInput) {
    TransferOwnership(input: $input)
  }
`;
export const activateOrDeactivateTemplate = /* GraphQL */ `
  mutation ActivateOrDeactivateTemplate(
    $input: ActivateOrDeactivateTemplateInput
  ) {
    ActivateOrDeactivateTemplate(input: $input)
  }
`;
export const updateIssuerDetails = /* GraphQL */ `
  mutation UpdateIssuerDetails($input: UpdateIssuerDetailsInput) {
    UpdateIssuerDetails(input: $input)
  }
`;
export const createPDFTemplate = /* GraphQL */ `
  mutation CreatePDFTemplate($input: CreatePDFTemplateInput) {
    CreatePDFTemplate(input: $input)
  }
`;
export const updatePDFTemplate = /* GraphQL */ `
  mutation UpdatePDFTemplate($input: UpdatePDFTemplateInput) {
    UpdatePDFTemplate(input: $input)
  }
`;
export const deletePDFTemplate = /* GraphQL */ `
  mutation DeletePDFTemplate($input: DeletePDFTemplateInput) {
    DeletePDFTemplate(input: $input)
  }
`;
export const updateIssuerURLsAndAPIId = /* GraphQL */ `
  mutation UpdateIssuerURLsAndAPIId($input: UpdateIssuerURLsAndAPIIdInput) {
    UpdateIssuerURLsAndAPIId(input: $input)
  }
`;
export const deleteSignatureURL = /* GraphQL */ `
  mutation DeleteSignatureURL($input: DeleteSignatureURLInput) {
    DeleteSignatureURL(input: $input)
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia($input: CreateMediaInput) {
    CreateMedia(input: $input)
  }
`;
export const activateOrDeactivateMedia = /* GraphQL */ `
  mutation ActivateOrDeactivateMedia($input: ActivateOrDeactivateMediaInput) {
    ActivateOrDeactivateMedia(input: $input)
  }
`;
export const issueCredentialsInBulk = /* GraphQL */ `
  mutation IssueCredentialsInBulk($input: IssueCredentialsInBulkInput) {
    IssueCredentialsInBulk(input: $input)
  }
`;
export const approveOrRejectVerificationRequest = /* GraphQL */ `
  mutation ApproveOrRejectVerificationRequest(
    $input: ApproveOrRejectVerificationRequestInput
  ) {
    ApproveOrRejectVerificationRequest(input: $input)
  }
`;
